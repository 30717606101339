<template>
  <div class="tooltip">
    <slot />
    <span class="tooltiptext text-white" :class="[position, size]">
      {{ text }}
    </span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true
    },

    position: {
      type: String,
      default: 'top'
    },

    size: {
      type: String,
      default: 'medium'
    }
  }
})
</script>

<style lang="postcss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  font-size: 0.8rem;
  visibility: hidden;
  background-color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: 50;
  left: 50%;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
  }
}

.top {
  bottom: 120%;

  &::after {
    top: 100%;
    border-color: #000 transparent transparent transparent;
  }
}

.bottom {
  top: 120%;

  &::after {
    bottom: 100%;
    border-color: transparent transparent #000 transparent;
  }
}

.small {
  width: 80px;
  margin-left: -40px;
}

.medium {
  width: 120px;
  margin-left: -60px;
}

.large {
  width: 160px;
  margin-left: -80px;
}

.extra-large {
  width: 200px;
  margin-left: -100px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
